/* You can add global styles to this file, and also import other style files */
@import "~ngx-smart-modal/ngx-smart-modal";

.node {
    cursor: pointer;
}
.modal-width {
    max-width: 100%!important;
}

.shipping-table {
  width: 850px !important;
}


.node text {
  font: 12px sans-serif;
}
.link text {
    color: black;
    font-size: 7px;
    text-align: center;
}
.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}
.link:hover {
    cursor: pointer;
}
.pointer:hover {
  cursor: pointer;
}
.linkMini {
    fill: none;
    stroke: #ccc;
    stroke-width: 1px;
}
app-root {
    background-color: #fafafa;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

.width-12 {
  width: 12.5%;
}

.width-20 {
  width: 20%;
}

.width-25 {
  width: 25%;
}

.width-33 {
  width: 33.3%;
}

.width-50 {
  width: 50%;
}

.width-60 {
  width: 60%;
}

.width-90 {
    width: 90%;
}
.width-100 {
    width: 100%;
}

.margin-auto {
  margin: 0 auto;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-30 {
  margin-top: 30px;
}

.valign-top {
  vertical-align: top;
}
.center {
  text-align: center;
}
/*app-header {
    background-color: #6477A7;
    flex-grow: 0;
    flex-shrink: 0;
}*/
app-content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: scroll;
}
::-webkit-scrollbar {
    display: none;
}

app-footer {
    background-color: #6477A7;
    flex-grow: 0;
    flex-shrink: 0;
    height: 44px;
    text-align: center;
    padding:10px;
    color:white;
}

app-sidebar {
    background-color: #ddd;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: scroll;
    min-width: 0px;
    max-width: 250px;
}
app-sidebar-left {
    background-color: #fff;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: scroll;
}
app-admin,
app-login,
app-user-settings,
app-discounts,
app-crud-permissions,
app-pharmacy,
app-add-edit-product,
app-add-edit-product-group,
app-add-edit-triage-product,
app-products-holder,
app-add-edit-collection,
app-staff-manager-holder,
app-add-edit-staff,
app-add-edit-practice,
app-services-manager-holder,
app-extended-data-holder,
app-create-edit-profile,
app-rules-manager-holder,
app-add-edit-rule,
app-create-edit-data-point,
app-create-edit-workflow,
app-workflows-manager,
app-appointment-types-holder,
app-practice-products-manager,
app-practices-holder,
app-refunds-holder {
    overflow: scroll;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
}

app-products, app-lab-tests-page {
  width: 100%;
  overflow: hidden;
}
/* app-file-uploader {
    width: 100%;
} */
/* app-user-settings {
    background-color: #eee;
} */
app-d3 {
    background-color: #eee;
    /* background-color: rgb(250,250,250); */
    flex-grow: 1;
    flex-shrink: 1;
    padding: 50px;
    overflow: scroll;
    margin-top: 60px;
}
app-alert {
    position: fixed;
    left: 0;
    right: 0;
    top: 75px;
    bottom: 0;
    pointer-events: none;
    z-index: 999;
}

app-alert .isDeep {
   margin-top: 50px;
}
/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */


.lds-roller {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #000;
    margin: -3px 0 0 -3px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.mat-header-cell {
    font-family: Chalet1980;
    color: #6477A7;
    font-size: 14px;
}
.table-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: scroll;
}
.updates-table .mat-header-row {
  top: 0px;
  z-index: 101;
  left: 0px;
  position: sticky;
  background: white;
}
.float-left {
    float: left;
}
.float-left:hover {
    cursor: pointer;
}
.mat-cell {
    font-family: ChaletLondon;
    font-size: 12px;
    color: #888;
}
.selected-path-title {
    font-family: Chalet1980;
    color: #6477A7;
    font-size: 24px;
}
.general-title {
  font-family: Chalet1980;
  color: #6477A7;
  font-size: 24px;
}

.add-new-button {
  /* background-color: #6477A7; */
  position: fixed;
  width: 100%;
  bottom: 0;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100px;
  text-align: center;
  padding: 10px;
}
.mat-row:hover {
  cursor: pointer;
  background-color: #f7f7f7;
}
.color-box {
    border: none;
    width: 40px;
}
.cursor:hover {
  cursor: pointer;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-horizontal-10 {
  margin: 0 10px;
}
.padding-right-10 {
  padding-right: 10px;
}
.vertical-align {
  font-family: ChaletLondon;
  color: #888;
  position:absolute;
  top: 30%;
  /* left:50%; */

}
.delete-button {
  color: #C32C00;
}
.edit-button {
  color: #24c1ea;
}
.chalet-font {
  font-family: Chalet1980;
}

.vet-ai-button {
  background-color: #24c1ea;
  color: white;
  font-family: Chalet1980;
  border: none;
}

.vet-ai-button:disabled {
  background-color: #abeeff;
}
.vet-ai-button-hollow {
  color: #24c1ea;
  background-color: white;
  font-family: Chalet1980;
  border: 1px solid #24c1ea;
}

.vet-ai-button-danger {
  background-color: white;
  color: red;
  font-family: Chalet1980;
  border: 2px solid red;
}

.vet-ai-button-warning {
  background-color: #E76E0F;
  color: white;
  font-family: Chalet1980;
  border: none;
}
.vet-ai-header {
  font-family: Chalet1980;
  color: #6477A7;
}
.small-button {
  padding: 8px 20px 8px 20px;
  font-size: 22px;
  border-radius: 26px;
}
.large-button {
  padding: 10px 24px 10px 24px;
  font-size: 18px;
  border-radius: 30px;
}
.close {
  position: relative;
  top: 0;
  right: 0;
}
.close:hover {
    cursor: pointer;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.decision-class {
  content: '';
  width: 0;
  height: 0;
  border:  var(--border-after);
  border-bottom:  var(--border-top-after);
  position: relative;
  left: var(--left);
  transform: rotate(-90deg);
}
.decision-class:after {
  content: '';
  position: absolute;
  left: var(--left-after);
  top:  var(--top-after);
  width: 0;
  height: 0;
  border: var(--border-after);
  border-top: var(--border-top-after);
}
.decision-container {
  width: 100%;
  text-align: center;
  vertical-align: middle;
  position:relative;

}
mat-icon-button {
  /* position: unset!important; */
  z-index: 10;
}
app-algorithms {
  background-color: white;
}
.mat-toolbar-row {
  z-index: 100;
}
.mat-toolbar-row-grey {
  z-index: 100;
  /* position: absolute; */
  background-color: #f7f7f7!important;
}
.mat-expansion-panel {
  background: #fafafa;
}
.mat-expansion-panel-header-title {
  align-items: center;
}
.total-container {
  /* background-color: #eee; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.shipping-input {
  font-size: 14px !important;
  padding: 10px !important;
  line-height: 16px !important;
  height: auto !important;
  position: relative;
  margin-right: 5px;
}

.mat-column-City,
.mat-column-Weight{
  max-width: 90px;
}

.mat-column-Postcode {
  max-width: 60px;
}

.mat-column-ServiceCode {
  max-width: 140px;
}

.mat-column-Weight input {
  width: 70%;
}

.mat-tab-label-content {
  font-size: 18px !important;
  font-family: ChaletLondon;
}
.mat-tab-label {
  color: #777;
  opacity: 1!important;
}
.mat-tab-label.mat-tab-label-active {
  background-color: transparent;
  color: #24c1ea;
  font-weight: bolder;
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
  background:#24c1ea;
  height: 5px;
}

.out-of-stock {
  color: #dc3545;
}

.available {
  color: #28a745;
}

.btn.draft, .status-draft {
  background: #FBDCC2;
  color: #E76E0F;
}
.btn.inactive, .status-inactive {
  background: #e3e3e3;
  color: #9c9c9c;
}
.btn.ready, .status-ready {
  background: #f7fd72;
  color: #9aa201;
}
.btn.ready:hover {
  background: #f2ff04;
  color: #808701;
}
.btn.inactive:hover {
  background: #cecece;
  color: #9c9c9c;
}
.status-none {
  background-color: #ddd;
  color: #666;
}
.btn.draft:hover {
  color: #FBDCC2;
  background: #E76E0F;
}

.btn.live, .status-live {
  background: #E7FFDD;
  color: #5BCC2E;
}

.btn.published, .status-published, .status-active {
  background: #E7FFDD;
  color: #5BCC2E;
}

.status-inactive.status-outline {
  border: 1px solid #9c9c9c;
}

.status-ready.status-outline {
  border: 1px solid #9aa201;
}

.status-draft.status-outline {
  border: 1px solid #E76E0F;
}

.status-published.status-outline, .status-live.status-outline, .status-active.status-outline {
  border: 1px solid #5BCC2E;
}

.btn.live:hover {
  color: #E7FFDD;
  background: #5BCC2E;
}

.btn.approved, .status-approved {
  background: #E0F0FE;
  color: #266FA1;
}

.btn.approved:hover {
  color: #E0F0FE;
  background: #266FA1;
}

.btn.in-review,
.status-in-review {
  background: #EEDEFE;
  color: #AE5AAC;
}

.btn.in-review:hover {
  color: #EEDEFE;
  background: #AE5AAC;
}

.btn.in-test,
.status-in-test {
  background: #FFFBDD;
  color: #ECA933;
}

.btn.in-test:hover {
  color: #FFFBDD;
  background: #ECA933;
}

.btn.paused, .status-paused {
  background: #E3E3E3;
  color: #AFAFAF;
}

.btn.paused:hover {
  color: #E3E3E3;
  background: #AFAFAF;
}

.img-delete {
  margin-top: -50px;
  position: relative;
  color: darkred;
}
.img-delete:hover {
  color: rgb(85, 0, 0);
  cursor: pointer;
}

.alert {
  width: 100%;
  position: fixed !important;
  z-index: 999;
  top: 60px;
  border-radius: 0 !important;
}

.mat-stroked-button.mat-primary {
  border: 2px solid #E76E0F;
  color: #E76E0F;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.remove-btn {
  width: 30px !important;
  height: 30px !important;
  margin-left: 5px !important;
  margin-right: 3px !important;
}

.search-clear {
  padding: 0 !important;
}

.search-clear-holder, .search-clear-holder .input-group-text {
  border-bottom-right-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.video-node-holder {
  text-align: left;
  border-bottom: 1px solid black;
  padding: 10px;
}

.video-node-holder p {
  margin-bottom: 0;
}

.image-node-holder {
  text-align: left;
  border-bottom: 1px solid black;
}

.image-node-holder p {
  width: 70%;
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
  margin-bottom: 0;
}

.image-thumbnail {
  max-width: 70px;
  display: inline-block;
}

.image-thumbnail img {
  width: 100%;
  max-height: 60px;
  height: auto;
}

.cdk-drag-preview, .cdk-drag-placeholder {
  background: white;
  padding: 10px;
}

.cdk-drag-preview button, .cdk-drag-placeholder button, .cdk-drag-preview hr, .cdk-drag-placeholder hr {
  display: none;
}

.cdk-drag-preview img, .cdk-drag-placeholder img, .cdk-drag-preview iframe, .cdk-drag-placeholder iframe {
  width: 100%;
}

.instructions-text {
  line-height: 18px;
}

.warning-text {
  color: red;
  font-style: italic;
}

.outline-btn {
  margin-left: 10px;
  color: #24c1ea;
  background-color: white;
  border: 1px solid #24c1ea;
  border-radius: 20px;
  padding: 2px 18px 2px 18px;
  margin-right: 5px;
}

.outline-btn:disabled {
  opacity: 0.5;
}

.del-btn {
  color: #C32C00;
  background-color: white;
  border:1px solid #C32C00;
  padding:2px 18px 2px 18px;
  border-radius: 20px;
}

.del-btn:hover {
  cursor: pointer;
  background-color: #C32C00;
  color: white;
}

.del-btn .mat-icon {
  position: relative;
  top: 3px;
}

.half-input {
  width: 49% !important;
}

.max-use-toggle .vet-ai-input {
  width: 35%;
  display: inline-block;
}

.max-use-toggle .max-use-toggle-input {
  width: auto;
  display: inline-block;
}

.max-use-toggle .max-use-toggle-label {
  display: inline-block;
  padding-left: 10px;
  text-align: left !important;
}

.btn-outline-secondary.btn-sm .mat-icon {
  position: relative;
  top: 2px;
}

.btn-outline-secondary.btn-sm span {
  position: relative;
  top: -5px;
}

.version-dialog .mat-dialog-container {
  position: relative;
}

.version-dialog-backdrop {
  background: rgba(0,0,0, 0.5);
}

.refund-orders-modal .mat-dialog-container {
  overflow: hidden;
}

.product-group-upsell-management-dialog .mat-dialog-container {
  width: 65vw;
  max-width: 800px;
  height: 55vh;
  min-height: 300px;
  position: relative;
}

.product-group-upsell-management-dialog .mat-dialog-content {
  height: 100%;
}

.joii-date-picker .mat-form-field {
  width: 100%;
  display: inline-block;
}

.joii-date-picker .mat-form-field-flex {
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.joii-date-picker .mat-form-field-infix {
  border-top: .2em solid transparent;
}

.joii-date-picker .mat-form-field-wrapper {
  padding-bottom: 0;
}

.joii-date-picker input {
  padding-left: .75rem;
  color: #495057;
}

.joii-date-picker p {
  margin-bottom: 0;
  color: #495057;
  position: absolute;
  top: 5px;
  left: .75rem;
}

.joii-date-picker .mat-form-field-underline {
  display: none;
}

.joii-date-picker .clear-btn {
  position: absolute;
  right: 5px;
  top: 3px;
  color: #495057;
  cursor: pointer;
}

app-add-edit-practice .mat-form-field-hint-spacer {
  display: none;
}

app-add-edit-practice .mat-form-field-hint-wrapper {
  justify-content: space-between;
}

.joii-date-picker .mat-datepicker-toggle {
  position: relative;
  top: -2px;
  right: 5px;
}

.joii-date-picker .mat-datepicker-toggle-default-icon {
  width: 1.3em !important;
}

.products-chips-holder .mat-chip {
  z-index: 2;
}

.products-chips-holder .mat-chip button {
  border: none;
  background: transparent;
}

.products-chips-holder .mat-chip button .mat-icon {
  position: relative;
  top: -4px;
  left: -7px;
}

.products-group-chips-holder .mat-chip {
  z-index: 2;
}

.products-group-chips-holder .mat-chip button {
  border: none;
  background: transparent;
}

.products-group-chips-holder .mat-chip button .mat-icon {
  position: relative;
  top: -4px;
  left: -7px;
}

/*.products-chips-holder .mat-chip-list-wrapper input {
  position: absolute;
}*/

.products-chips-holder.no-options .mat-chip-list-wrapper input {
  position: relative;
  margin-top: 18px;
}

.products-group-chips-holder.no-options .mat-chip-list-wrapper input {
  position: relative;
  margin-top: 4px;
}

.btns-wide-holder {
  text-align: center;
}

.btns-wide-holder button {
  width: 30%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.btns-wide-holder button:last-child {
  margin-right: 0;
}

mark.orange {
  padding: 0;
  background: none;
  color: darkorange;
}

.multi-line-tooltip {
  white-space: pre-line;
}

.notification-btn {
  width: 100%;
  background: #49B9BA;
  color: #FFF;
  border-radius: 20px !important;
  text-align: left !important;
  position: relative !important;
}

.notification-btn mat-icon {
  color: #49B9BA;
  background: #FFF;
  border-radius: 50%;
  position: absolute;
  right: 4px;
  top: 50%;
  width: 30px;
  height: 30px;
  font-size: 30px;
  transform: translateY(-50%);
}

.image-gallery img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 200px;
}

.gallery-column {
  margin-bottom: 10px;
  text-align: center;
}

.page-title {
  position: relative;
  top: 2px;
}

.image-holder {
  cursor: pointer;
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  cursor: not-allowed;
}

.overlay .overlay-info {
  position: absolute;
  left: calc(50% + 7px);
  top: calc(50% + 7px);
  transform: translate(-50%, -50%);
}

.overlay-info.chosen-image {
 box-sizing: border-box;
 border: 5px solid #3297ce;
}
.gallery-holder {
  padding-left: 0;
  padding-right: 0;
}
.gallery-holder nav {
  text-align: center;
  margin-bottom: 10px;
  user-select: none;
}
.gallery-holder .mat-icon-button {
  outline: none;
}

.joii-icon-btn {
  display: inline-flex !important;
}

.joii-icon-btn .mat-icon {
  margin-left: -5px !important;
}

.selected-import-product {
  border: 1px solid #D8D8D8;
  margin: 10px 0;
}

.selected-import-product header {
  background: #D8D8D8;
  padding: 10px;
}

.selected-import-product .import-product-details {
  padding: 10px;
}

.joii-inline-select .mat-form-field-infix {
  width: auto;
  min-width: 90px;
  border-top: none;
}

.joii-inline-select .mat-form-field-wrapper {
  padding-bottom: 10px;
}

.joii-inline-select .mat-form-field-underline {
  bottom: 10px;
}

.btn-icon-success .mat-icon {
  color: #28a745;
}

.btn.btn-purple {
  background: #AA66CC !important;
  color: white !important;
}

.mat-tab-label {
  height: 40px;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background: #6477A7 !important;
}

.mat-tab-label.mat-tab-label-active {
  color: #6477A7 !important;
}

.search-type-toggles {
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: 999;
}

.search-type-toggles.has-search-term {
  right: 50px;
}

.search-type-toggles .mat-icon {
  cursor: pointer;
  position: relative;
  top: 3px;
}

.search-type-toggles button {
  outline: none;
}

.search-icon, .toggle-icon {
  color: #6477A7 !important;
}

.search-by-type-holder {
  position: absolute;
  left: 45px;
  top: -2px;
  z-index: 999;
}

.search-by-type-holder .form-control {
  height: 30px;
  padding: 5px;
  font-size: 14px;
  position: relative;
  top: 6px;
}

.product-search-input {
  padding-left: 80px !important;
}

.details-multi-tooltip {
  white-space: pre-line;
  text-transform: capitalize;
}

.permissions-type-inputs {
  margin-right: 10px;
}

.tinted-rounded-row {
  background: #e1e8fc;
  border-radius: 6px;
  padding-top: 7px;
}

.appointment-type-label {
  margin-top: 15px;
  display: inline-block;
}

.addresses-holder {
  border: 1px solid #AFAFAF;
  padding: 5px;
}

.address-item {
  padding: 10px;
  cursor: pointer;
}

.address-item:hover {
  background: #AFAFAF;
}

.nsm-content {
  color: black;
}
/* Not keen on this, but was having some layout issues in parent */
app-media-manager-preview {
  width: 80px;
  height: 80px;
  display: inline-block;
  margin-right: 10px;
}

app-media-manager-input {
  display: inline-block;
  vertical-align: top;
}

.cdk-drag.workflow-phase {
  padding: 0;
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  width: 24px !important;
}

.batch-form .mat-form-field-appearance-legacy .mat-form-field-prefix,
.batch-form .mat-form-field-appearance-legacy .mat-form-field-suffix {
  position: relative;
  top: 25px;
}

.mat-paginator.practice-appointments {
  margin-top: 10px;
  background: #fafafa;
}
