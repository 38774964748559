@font-face {
    font-family: Gotham;
    src: url('./assets/fonts/Gotham/gothamrounded_book.otf') format("truetype");
}

@font-face {
    font-family: GothamBol;
    src: url('./assets/fonts/Gotham/GothaBol') format('truetype');
}

@font-face {
    font-family: GothamBold;
    src: url('./assets/fonts/Gotham/gotham_bold') format("truetype");
}

@font-face {
    font-family: GothamLight;
    src: url('./assets/fonts/Gotham/gothamrounded_light.otf') format("truetype");
}

@font-face {
    font-family: Chalet1980;
    src: url('./assets/fonts/Chalet/ChaletNewYorkNineteenEighty.ttf') format("truetype");
}

@font-face {
    font-family: ChaletLondon;
    src: url('./assets/fonts/Chalet/ChaletLondonNineteenEighty.ttf') format("truetype");
}